import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hollow Rocks 4 x :30`}</p>
    <p>{`and`}</p>
    <p>{`Banded Good Mornings 4 x 10`}</p>
    <p>{`then,`}</p>
    <p>{`Deadlifts 5×2\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`Deadlifts (225/155)(RX+ 275/185)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      